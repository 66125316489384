// function initConfig() {
//   // Firebase configuration
//   var firebaseConfig = {
//     apiKey: "AIzaSyCss8833TpaKYlIrRsC_VkrxKQC-Uuf_nI",
//     authDomain: "chat-murcianys.firebaseapp.com",
//     databaseURL: "https://chat-murcianys.firebaseio.com",
//     projectId: "chat-murcianys",
//     storageBucket: "chat-murcianys.appspot.com",
//     messagingSenderId: "484066067543",
//     appId: "1:484066067543:web:b12e176ab6ad132a0e8f12",
//   };
//   // Initialize Firebase
//   firebase.initializeApp(firebaseConfig);
// }
// initConfig();
const DATABASE_IN_APP_PRODUCTS = "https://chat-murcianys-18752.firebaseio.com/";
const DATABASE_IN_APP_PURCHASES =
  "https://chat-murcianys-efb7f.firebaseio.com/";
const DEFAULT_DATABASE = {
  apiKey: "AIzaSyCss8833TpaKYlIrRsC_VkrxKQC-Uuf_nI",
  authDomain: "chat-murcianys.firebaseapp.com",
  databaseURL: "https://chat-murcianys.firebaseio.com",
  projectId: "chat-murcianys",
  storageBucket: "chat-murcianys.appspot.com",
  messagingSenderId: "484066067543",
  appId: "1:484066067543:web:b12e176ab6ad132a0e8f12",
};
var productApp;
var defApp;
var purApp;
function initConfig() {
  // Initialize Firebase
  defApp = firebase.initializeApp(DEFAULT_DATABASE);
  productApp = defApp.database(DATABASE_IN_APP_PRODUCTS);
  purApp = defApp.database(DATABASE_IN_APP_PURCHASES);
}
initConfig();
export const prodApp = productApp;
export const defAppRef = defApp;
export const purchaseApp = purApp;
export const vapidKey =
  "BDbMU_3OpCR3PBhal6ZBlAR4SeKUC6m0rDLCgWh8YERs2XiQXUXSV1T2h3AG2wKbMAopHxv-UTkIsMqlmrgL1v0";
